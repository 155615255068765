import React, { useState } from 'react';
import SiteLayout from '../components/layout/SiteLayout';
import { graphql, Link } from 'gatsby';

const ErrorPage = ({ data }) => {
  const [pageData, setPageData] = useState(data?.errorPage?.posts[0]);

  return pageData && (
    <SiteLayout title={pageData?.frontmatter?.title}>
      <div className={"w-full flex flex-col justify-center mb-20"}>
        <h1 className={'text-center mt-5 text-primary-default text-3xl font-semibold'}>{pageData?.frontmatter?.title}</h1>
        <img className={"w-full md:w-2/3 self-center"} src={pageData?.frontmatter?.image?.publicURL} />
        <Link to={"/"} className={"self-center"}>
          <div className={"mt-10 uppercase text-primary-default cursor-pointer border-2 rounded-2xl w-auto text-center border-primary-default px-5 py-2 hover:bg-primary-default hover:text-white font-bold"}>
            {pageData?.frontmatter?.buttonText}
          </div>
        </Link>
      </div>
    </SiteLayout>
  );
}

export const query = graphql`
 query ErrorPage {
  errorPage: allMdx(filter: {frontmatter: {type: {eq: "error-page"}}}) {
    posts: nodes {
      id
      fields {
        slug
      }
      frontmatter {
        type
        title
        metaTitle
        metaDescription
        image {
          publicURL
        }
        buttonText
      }
    }
  }
}
`

export default ErrorPage;
